@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide>div{
   margin: 0 10px;
}
body{
  @apply font-title
}
.slick-slide {
  margin: 0;
  padding: 0;
  height: auto!important; /* Adjust this value as needed */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important; 
 
}

/* Override default height */
.slick-slide img {
  height: auto !important; /* Ensure images scale properly */
  max-height: 100%;         /* Prevent excessive image height */
}

/* Ensure the container does not stretch */
.slick-track {
  display: flex;
  align-items: center; /* Center content if needed */
  height: auto !important; /* Adjust the track height */
  
}