
.custom-bg{
  @apply bg-button-gradient border-button-border shadow-shadow-1 hover:shadow-shadow-2 
}
.custom-vote-bg{
  background: linear-gradient(75deg, #30A6EF, #ffff);
}
.custom-l-bg{
 @apply hover:border hover:border-[#34A9F14D]
}
.custom-l-bg2{
  background: linear-gradient(75deg, #f0f1f1e5, #4aa2bd);
}
.Hero-section{
    background-image: url('./assets/frame.jpg');
    background-size: cover;

}
.telegram-section{
  background-image: url('./assets/frame.jpg');
  background-size: cover;

}
.purpose-bg{
    background-image: url('./assets/dots.png');
    background-size: contain;
}

.join-bg{
    background-image: url('./assets/joinframe.jpg');
    background-size: cover;
}
.custom-blue{
    background: #1D689D36;
}
.hide-scrollbar::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }
  
  .hide-scrollbar {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    scrollbar-width: none; /* For Firefox */
  }
  .draganddrop {
    background-image: url('./assets/dots.png');
    background-size: contain; 
  }
  .alert-bg {
    background: linear-gradient(to bottom, #9047EE, #D8BEF9, #FFFFFF )
  }
  .openSea {
    background: linear-gradient(to bottom, #18C55D, #9EE7BB, #FFFFFF )
  }
  .tokenSwap {
    background: linear-gradient(to bottom, #FD903E, #FECFAB, #FFFFFF )
  }
  .telegram {
    background: linear-gradient(to bottom, #FD903E, #FECFAB, #FFFFFF )
  }
  .botoimg {
    width:25px;
    height: 22px;
  }
  .teleimg {
    width:25px;
    height: 22px;
  }
  .swapimg {
    width:25px;
    height: 22px;
  }
  .voiceimg {
    width:30px;
    height: 30px;
  }
  .swapimg {
    width:25px;
    height: 22px;
  }
  .features-join {
    background: linear-gradient(to bottom, #1E7FF5, #025ECF )
  }

  
  .custom-checkbox,
  .custom-radio {
    appearance: none; /* Removes default styles */
    -webkit-appearance: none; /* Removes default styles for WebKit browsers */
    background-color: #ffffff; /* Default background color */
    border: 2px solid #D1D1D6; /* Border for unselected state */
    border-radius: 4px; /* Rounded corners for checkboxes */
    width: 20px;
    height: 20px;
    display: inline-block;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  .custom-checkbox:checked {
    background-color: #4497F8; /* Background color when checked */
    border-color: #4497F8; /* Border color when checked */
  }
  .custom-checkbox:checked::before {
    content: '✔';
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .custom-radio {
    border-radius: 50%; /* Make it circular for radio buttons */
  }
  .custom-radio:checked {
    background-color: #ffffff; /* Background color when checked */
    border-color: #D1D1D6; /* Border color when checked */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-radio:checked::before {
    content: '';
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10px;
    background-color: #4497F8;
    border-radius: 50%;
  }
  .body-scroll-lock {
    overflow: hidden;
    touch-action: none; /* Prevent scrolling on touch devices */
    position: fixed;
    inset: 0; /* Prevent any page shifting */
    height: 100%;
    width: 100%;
}
select option:focus {
  background-color: #f0f0f0; /* Hover background color */
  color: #000; /* Text color */
}